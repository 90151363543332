<template>
  <td>
    <ImageWithDescription
      class="default-style"
      :hideDescription="true"
      :hideEditButtons="true"
      :hideDownloadButtons="true"
      :value="item"
      @open="() => onClick('open')"
      @openPdf="() => onClick('pdf')"
      @delete="() => onClick('delete')"
      @edit="() => onClick('edit')"
      miniature
    ></ImageWithDescription>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined,
    },
    item: {
      default: undefined,
    },
    value: {},
  },
  components: {
    ImageWithDescription: () => import("@/components/ImageWithDescription"),
  },
  methods: {
    onClick(action) {
      this.$emit("action", { item: this.item, action: action });
    },
  },
};
</script>

<style lang="scss" scoped>
.default-style {
  height: 50px;
  width: 50px;
}
</style>